import { Component, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {Routes, RouterModule, ActivatedRoute, Router, Params} from '@angular/router';
import { DataAnalysisService } from '../../services/data-analysis/data-analysis.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Angular2Csv } from 'angular2-csv/Angular2-csv';
import { BrandService } from '../../services/brand/brand.service';
import { AppSettings } from '../../settings.global';
import { Observable, fromEvent } from 'rxjs/';
import { LoaderService } from '../../services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TitleCasePipe } from '@angular/common';
declare var $:any;

@Component({
  selector: 'app-data-analysis',
  templateUrl: './data-analysis.component.html',
  styleUrls: ['./data-analysis.component.scss'],
  providers : [TitleCasePipe]
})

export class DataAnalysisComponent implements OnInit {

  @ViewChild('keyword') keywords: ElementRef;
  @ViewChild('isp') isp: ElementRef;
  @ViewChild('seller') seller: ElementRef;
  @ViewChild('recidivist') recidivist: ElementRef;
  @ViewChild('product') product: ElementRef;
  @ViewChild('infringement') infringement: ElementRef;

  modalRefKeywords: BsModalRef;
  modalRefRecidivist: BsModalRef;
  modalRefSeller: BsModalRef;
  modalRefIsp: BsModalRef;
  modalRefProduct: BsModalRef;
  modalRefScreenshot: BsModalRef;
  modalRefThumbnail: BsModalRef;
  modalRefScreen: BsModalRef;
  modalRefInfringement:BsModalRef;
  modalRefSellerLike:BsModalRef;

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };
  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()],
      // '1st of the Month' :[moment().startOf('month') , moment()]
    },
    opens: 'left',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1
    },
    applyOnMenuSelect: true
    
  };
  searchBase: {
    brandId: number,
    reportedLink: boolean,
    region: string | number,
    fromDate: string,
    toDate: string
  };
  public stackchartOption: any;
  public dataStackBar: any;
  public chartOption: any;
  public dataScatterKeywords: any;
  public dataScatterIsp: any;
  public bubbleChartOption: any;
  public dataBubble:any;
  public type:any;
  public request: any;
  public lsData: any;
  public colorSpan: any;
  public urlData: any;
  public sellerName: any;
  public dataTable: any;
  public sellerDetails: any;
  public brandName: any;
  public thumbnail: any;
  public img_url = AppSettings.IMAGE_BASE_URL;
  public screenshot_url = AppSettings.SCREENSHOT_BASE_URL
  public activeIndex: any;
  public color1: any;
  public color2: any;  
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");
  public pieData :any;
  public pieStatus : any;
  public colors:any;
  public dataPieTypeInfringement:any;
  public selectedDateRangeLabel : any;
  public dataPackedCircle : any;
  public dataPackedCircleKeywords : any;
  public mostPopularKeywords : any;
  public mostPopularProducts : any;  
  public brandHeaderList : any;
  public brandHeaderListKeywords : any;
  public brandHeaderListProducts : any;
  public viewMoreProductList : any;
  public viewMoreKeywordsList : any;
  public testKeywords : any;
  public nodataStatus : any;
  public pieDataCheck : any; 
  public multiChartColors=[];
  public stackArray=[]; 
  public finalData=[];
  public sellers: string[] = [];
  public categories: Array<{key: string, value: string}> = [
    {key: 'm_commerce', value: 'M-Commerce'},
    {key: 'e_commerce', value: 'E-Commerce'},
    {key: 'social_media', value: 'Social Media'}
  ];
  public selectedSeller: string ='null';
  public selectedCategory: string;
  public chartData: any[] = [];
  public likeData:any= {};
  public previousCategory: string;

  constructor(private modalService: BsModalService,
    private lsService: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private brandService: BrandService,
    private authServiceObj:AuthService,
    private toastr: ToastrService,
    private loaderService:LoaderService,
    private dataAnalysisService: DataAnalysisService,
    private titleCasePipe:TitleCasePipe) {

    this.selectedDateRangeLabel = this.lsService.retrieve('seleceddatelabel'); 
    this.pieDataCheck = [];
    this.nodataStatus = 10;
    this.dataStackBar = [];
    this.dataScatterKeywords = [];
    this.dataScatterIsp = [];
    this.dataBubble = [];
    this.dataPackedCircle = {};
    this.dataPackedCircleKeywords = {};
    this.mostPopularKeywords = {};
    this.mostPopularProducts = {};    
    this.lsData = JSON.parse(this.lsService.retrieve('search_base'));
    this.searchBase = {
      brandId: this.lsService.retrieve('active_brand_id'),
      reportedLink: this.lsData.reportedLink,
      region: this.lsData.region,
      fromDate: this.lsData.fromDate,
      toDate: this.lsData.toDate
    };
    this.daterange.start = this.lsData.fromDate;
    this.daterange.end = this.lsData.toDate;
    this.request = {
      brand_id : this.lsService.retrieve('active_brand_id'),
      fromDate : this.daterange.start,
      toDate : this.daterange.end
    };
    this.urlData = [];
    this.sellerName = '';
    this.dataTable = [];
    this.sellerDetails = {
      type : '',
      seller_name : '',
      brand_id : this.lsService.retrieve('active_brand_id'),
      fromDate : this.daterange.start,
      toDate : this.daterange.end
    }
    this.brandName = '';
    this.thumbnail = [];
    this.activeIndex = 0;
    const selBrands= this.lsService.retrieve('selectedbrands');
    if(selBrands.length>0){
      try {
        this.stackArray = JSON.parse(this.lsService.retrieve('selectedbrands'));
      } catch (error) {
        this.stackArray=[];
      }
    }
    // this.multiChartColors = ['#f0d128','#6c7279','#3397d8','#f09328','#f06128','#f02828','#58cc73','#58ccc2','#cc58bc','#1c3545'];
    this.multiChartColors = ['#f0d128','#c75ac7','#3397d8','#f09328','#f06128','#f02828','#58cc73','#58ccc2','#cc58bc','#1c3545','#6c7279'];
    
    // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]   
    this.datepickerOptions.startDate =  new Date(this.lsData.fromDate);
    this.datepickerOptions.endDate = new Date(this.lsData.toDate);
  }

  ngOnInit() {   
    this.selectedCategory = this.categories[0].key; 
    this.getData(this.request)
    this.activatedRoute.params.subscribe((params: Params) => {
      this.type = params['type'];
      if(this.type == 'keywords'){
        let el: HTMLElement = this.keywords.nativeElement as HTMLElement;
        el.click();
      }else if(this.type == 'isp'){
        let el: HTMLElement = this.isp.nativeElement as HTMLElement;
        el.click();
      }else if(this.type == 'seller'){
        let el: HTMLElement = this.seller.nativeElement as HTMLElement;
        el.click();
      }else if(this.type == 'recidivist'){
        let el: HTMLElement = this.recidivist.nativeElement as HTMLElement;
        el.click();
      }else if(this.type == 'product'){
        let el: HTMLElement = this.product.nativeElement as HTMLElement;
        el.click();
      }else if(this.type == 'infringement'){
        let el: HTMLElement = this.infringement.nativeElement as HTMLElement;
        el.click();
      }
    });

    this.loadLikesInitialData();
    this.previousCategory = this.categories[0].key;
  }

  selectedDate(value: any) {

    this.selectedDateRangeLabel = value.label;   
    this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel);  
        
    this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
    this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');

    this.request.fromDate =  this.datepickerOptions.startDate;
    this.request.toDate =  this.datepickerOptions.endDate;    
    this.sellerDetails.fromDate =  this.datepickerOptions.startDate;
    this.sellerDetails.toDate =  this.datepickerOptions.endDate;

    this.searchBase.fromDate = this.datepickerOptions.startDate ;
    this.searchBase.toDate = this.datepickerOptions.endDate;

    // this.daterange.start = moment(value.start._d).format('YYYY-MM-DD');
    // this.daterange.end = moment(value.end._d).format('YYYY-MM-DD');

    // this.request.fromDate = this.daterange.start;
    // this.request.toDate = this.daterange.end;    
    // this.sellerDetails.fromDate = this.daterange.start;
    // this.sellerDetails.toDate = this.daterange.end;

    // this.searchBase.fromDate = this.daterange.start;
    // this.searchBase.toDate = this.daterange.end;
       
    this.getData(this.request); 
    this.onSelectionChange();   
  }

  //get the count of product corresponding to the brand
  mapHeader(header:any, product:any){
    return product[header];    
  }

  
  //get analysis data
  getData(request:any){
    this.lsService. store('search_base', JSON.stringify(this.searchBase));
    this.loaderService.display(true);
    this.dataAnalysisService.getDataAnalysis(this.request).subscribe( (res) => {
      this.loaderService.display(false);
      if(res.json().status == "success"){
        this.dataScatterIsp = res.json().data_found.isp;
        this.dataScatterKeywords = res.json().data_found.popular_keyword;        
        this.dataBubble = res.json().data_found.popular_products[0];        
        this.dataPackedCircle = res.json().data_found.popular_products[0];
        this.dataStackBar = res.json().data_found.biggest_seller;
        this.dataTable = res.json().data_found.recidivist_sellers;
        this.brandName = res.json().data_found.brand;
        this.dataPieTypeInfringement = res.json().data_found.type_of_infringement; 
        this.pieDataCheck = res.json().data_found.type_of_infringement; 
        // In this response the 'product' inside children refers to the keywords.[product=keywords]
        this.dataPackedCircleKeywords = res.json().data_found.popular_keyword;
      //---------------------Biggest seller starts-----------------------
        var displayData = this.dataStackBar;
        var stackData = this.stackArray;    
        var stacklength = stackData.length;
        var stackKey = [];
        
        for(var m=0;m<stacklength;m++){
          stackKey[m] = stackData[m].name;
        }
        var arrayLength  = stackData.length;
        var sellerLength = displayData.length;
    
        for(var t=0;t<sellerLength;t++){
          let sellerData= displayData[t];              
          var counts=sellerData.counts;
          var json={};
              json["name"]=sellerData.seller_name;
              json["seller_name"]=sellerData.seller_name2;
              json["total"]=sellerData.total;
          var sellerJson = "{"+sellerData.counts+"}";
              sellerJson = sellerJson.replace(/'/g, '"')
              sellerJson= JSON.parse(sellerJson);
    
                  for(var p=0;p<arrayLength;p++){
                    if(sellerJson[stackKey[p]]){
                      json[stackKey[p]]=sellerJson[stackKey[p]];
                    }else{
                      json[stackKey[p]]=0;
                    }
                  }
    
             this.finalData.push(json);               
            //Sort the viewMoreProductList based on no.of urls
            //_.sortBy(this.viewMoreProductList, 'productName')
            this.finalData = this.finalData.sort((a, b) => (a.total < b.total) ? 1 : -1)                  
        }

        if(this.dataStackBar!=null){
          //this.biggestSeller
          var count = 0;
          
          for(let seller of this.dataStackBar) {
            // console.log("The seller is:",count,seller);
            count++;
          }
        }
        //---------------------Biggest seller ends-----------------------


       //-----------Most popular keywords..Json to draw packed circle graph start----------        
        if(this.dataScatterKeywords.children !=null) {       
          this.mostPopularKeywords = this.dataScatterKeywords;
          this.mostPopularKeywords['name'] = 'most_popular_keywords';        
          
          for(let singleBrand of this.dataScatterKeywords.children) {
            for(let singleProduct of singleBrand.children) {
              if(!('market_place' in singleProduct)) {
                singleProduct['market_place'] = 0;
              }
              if(!('social_media' in singleProduct)) {
                singleProduct['social_media'] = 0;
              }
              if(!('stand_alone' in singleProduct))  {
                singleProduct['stand_alone'] = 0;
              }          
            }          
          }          
        }
        // console.log('@@@@@@this.dataPackedCircleKeywords',this.dataPackedCircleKeywords)
        // console.log('@@@@@@this.mostPopularKeywords',this.mostPopularKeywords)
        //-----------Most popular keywords..Json to draw packed circle graph end----------   

        //--Most popular products in market place and social media..Json to draw packed circle graph start----------          
        if(this.dataBubble.children !=null) {       
          this.mostPopularProducts = this.dataBubble;                 
          
          for(let singleBrand of this.dataBubble.children) {
            for(let singleProduct of singleBrand.children) {
              if(!('market_place' in singleProduct)) {
                singleProduct['market_place'] = 0;
              }
              if(!('social_media' in singleProduct)) {
                singleProduct['social_media'] = 0;
              }                      
            }          
          }          
        }
        // console.log('@@@@@@this.dataBubble',this.dataBubble)
        // console.log('@@@@@@this.mostPopularProducts',this.mostPopularProducts)
        //--Most popular products in marketplace and social media..Json to draw packed circle graph end----------   
        // console.log('&&&&&&dataTable-',this.dataTable)
        // console.log('&&&&&&dataPieTypeInfringement-',this.dataPieTypeInfringement)

        //-------Product with most volume on social media and marketplaces..View more listing start------ 
        this.brandHeaderListProducts = [];
        this.viewMoreProductList = [];     
        var tempdataPackedCircleProducts = JSON.parse(JSON.stringify(this.mostPopularProducts));                                  
          if(this.dataBubble.children !=null) {       
            this.brandHeaderListProducts = []; 
            var pushData1 = {}; 

            for(let onebrand of tempdataPackedCircleProducts.children) {              
              this.brandHeaderListProducts.push(onebrand.brand);              
              for(let oneProduct of onebrand.children) {
                pushData1 = {};
                if(!(this.viewMoreProductList.find(x => x.productName === oneProduct.product))){                 
                  if(oneProduct.product!='') {
                    pushData1['productName'] = oneProduct.product;
                  } else {
                    pushData1['productName'] = 'Unknown Product';
                  }
                  pushData1[onebrand.brand] = oneProduct.count;
                  pushData1['total'] = oneProduct.count;
                  this.viewMoreProductList.push(pushData1);
                } else { 
                    var rowIndex = this.viewMoreProductList.findIndex(x => x.productName === oneProduct.product);
                    this.viewMoreProductList[rowIndex][onebrand.brand] = oneProduct.count;
                    var total = this.viewMoreProductList[rowIndex]['total'];
                    this.viewMoreProductList[rowIndex]['total'] = total+oneProduct.count;                                  
                }
              }    
            }   
            //Sort the viewMoreProductList based on no.of urls
            // _.sortBy(this.viewMoreProductList, 'productName')
            this.viewMoreProductList = this.viewMoreProductList.sort((a, b) => (a.total < b.total) ? 1 : -1)     
          }
        // console.log('@@@@this.brandHeaderListProducts--',this.brandHeaderListProducts)
        // console.log('@@@@viewMoreProductList--',this.viewMoreProductList)  
        
               
        //-------Product with most volume on social media and marketplaces..View more listing end------

        //--------------------Most popular keywords..View more listing start------------------
        this.brandHeaderListKeywords = [];
        this.viewMoreKeywordsList = [];       
        var tempdataPackedCircleKeywords = JSON.parse(JSON.stringify(this.mostPopularKeywords));                          
          if(this.dataScatterKeywords.children !=null) {       
            this.brandHeaderListKeywords = []; 
            var pushData1 = {}; 
            for(let onebrand of tempdataPackedCircleKeywords.children) {              
              this.brandHeaderListKeywords.push(onebrand.brand);   
              
              for(let oneProduct of onebrand.children) {
                pushData1 = {};

                if(!(this.viewMoreKeywordsList.find(x => x.productName === oneProduct.product))){          
                  pushData1['id'] = oneProduct.keyword_id;
                  if(oneProduct.product!='') {
                    pushData1['productName'] = oneProduct.product;
                  } else {
                    pushData1['productName'] = 'unknown keywords';
                  }
                  pushData1[onebrand.brand] = oneProduct.count;
                  pushData1['total'] = oneProduct.count;
                  this.viewMoreKeywordsList.push(pushData1);
                } 
                else {                                   
                    var rowIndex = this.viewMoreKeywordsList.findIndex(x => x.productName === oneProduct.product);
                    this.viewMoreKeywordsList[rowIndex][onebrand.brand] = oneProduct.count;
                    var total = this.viewMoreKeywordsList[rowIndex]['total'];
                    this.viewMoreKeywordsList[rowIndex]['total'] = total+oneProduct.count;                 
                }
              }    
            } 
            //Sort the viewMoreKeywordsList based on no.of urls
            // _.sortBy(this.viewMoreKeywordsList, 'productName')
            this.viewMoreKeywordsList = this.viewMoreKeywordsList.sort((a, b) => (a.total < b.total) ? 1 : -1)      
          }
        // console.log('@@@@this.brandHeaderListKeywords--',this.brandHeaderListKeywords)
        // console.log('@@@@viewMoreKeywordsList--',this.viewMoreKeywordsList) 
        
               
        //--------------------Most popular keywords..View more listing end------------------
         
        // this.dataStackBar = res.json().data_found.biggest_seller;
        // this.dataTable = res.json().data_found.recidivist_sellers;
        // this.brandName = res.json().data_found.brand;
        // this.dataPieTypeInfringement = res.json().data_found.type_of_infringement;

        // this.colors = ['#4d4d00','#808000','#b3b300','#cccc00','#d7b70f','#f0d028']; 
        this.colors = ['#003d66','#005c99','#007acc','#0099ff','#33adff','#80ccff'];  
        this.pieStatus = [];
        this.pieData = [];
        let row; let i=0;         
        if(this.dataPieTypeInfringement!=null) {    
            let length=  this.dataPieTypeInfringement.length;    
            let j = 0; let tempRow; let rowOthers :any;    
            while(j<length){          
              tempRow = this.dataPieTypeInfringement[j];                     
                if(tempRow.type_of_infringement == 'Others') {
                    rowOthers = tempRow;             
                }          
              j++;
            }                   
            while(i<length) {          
              row = this.dataPieTypeInfringement[i];                
                if(row.type_of_infringement !='Others') {
                  this.pieStatus.push(row.type_of_infringement);
                  this.pieData.push(row.total)
                }          
              i++;
            } 
            if(rowOthers){
               if((rowOthers.total!=null) && (rowOthers.total!='')) {           
               this.pieStatus.push(rowOthers.type_of_infringement);
               this.pieData.push(rowOthers.total)     
               }
            }
        }


      }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }      
    })
      
  }


 
 
  //Highlight legent and corresponding count in pie chart
  customizePie( selection, pieStatus, pieData ) {   

    selection.select('path')
              .attr('stroke-width', 2)
              .attr('stroke', '#fff');
    selection.append('title')
      // .text((datum, index) => (pieStatus[index]) + ' : ' + pieData[index]);            
      .text((datum, index) => this.titleCasePipe.transform(pieStatus[index]) + ' : ' + pieData[index]);    
      
  }

  onInfringementChartUpdate(selection) {
    this.customizePie(selection, this.pieStatus,this.pieData);
  }

 //open modal for kewordsgraph
  openModalKeywords(template: TemplateRef<any>){
    this.modalRefKeywords = this.modalService.show(template, { class: 'modal-key' });   
  }
  
  //open modal for recidivist graph
  openModalRecidivist(template: TemplateRef<any>){
    this.modalRefRecidivist = this.modalService.show(template);   
  }
  
  //open modal for biggest seller graph
  openModalSeller(template: TemplateRef<any>){
    this.modalRefSeller = this.modalService.show(template, { class: 'modal-seller' });   
  }

  //open modal for sellers like graph
  openModalSellerLike(template: TemplateRef<any>){
    this.modalRefSellerLike = this.modalService.show(template, { class: 'modal-seller' });   
  }

  //open modal for  ISP graph
  openModalIsp(template: TemplateRef<any>){
    this.modalRefIsp = this.modalService.show(template, { class: 'modal-key' });   
  }

  //opem modal for product graph
  openModalProduct(template: TemplateRef<any>){
    this.modalRefProduct = this.modalService.show(template, { class: 'modal-key' });   
  }

  //open modal for  Type of Infringement pie chart
  openModalInfringemnt(template: TemplateRef<any>){
    this.modalRefInfringement = this.modalService.show(template, { class: 'modal-key' });   
  }

  // To return brand count
  findBrandCount(brand:any,index:any){
    let data = this.finalData[index];
    let brandName=brand["name"];
    return data[brandName];
  }


  exportUrls(sellerName: any, type:any, keyword:any){
    this.sellerName = sellerName;
    this.sellerDetails.seller_name = sellerName;
    if(keyword){
      this.sellerName = keyword;
    }
    this.sellerDetails.type = type;
    this.loaderService.display(true);
    this.dataAnalysisService.getScreenShotData(this.sellerDetails).subscribe( (res) => {
      this.loaderService.display(false);
      if(res.json().status == "success"){
        //Copy to variable to avoid original data mutation

      this.urlData = res.json().data_found;
      const urlData = res.json().data_found;

      //Format the data to avoid unwanted fields
      var keyMap = {
        url : 'Url',
        id : 'Id',
        brand : 'Brand',
        country : 'Country',
        status : 'Status',
        social_network_link : 'SocialNetworkLink',
        marketplace_link : 'MarketPlaceLink',
        standalone_website_link : 'StandaloneWebsiteLink',
        seller_name: 'SellerName',
        phone_number_1: 'PhoneNumber1',
        phone_number_2: 'PhoneNumber2',
        email_address_1: 'EmailAddress1',
        email_address_2: 'EmailAddress2',
        address: 'Address'

      };

      var excelData = urlData.map((obj) => {
        return _.mapKeys(obj, (value, key) => {
          return keyMap[key];
        });
      });

      const excelDataModified = excelData.map((datum, i) => {
        return {
          Url: datum.Url,
          Id: datum.Id,
          Brand: datum.Brand,
          Country: datum.Country,
          Status: datum.Status ? datum.Status: '',
          SocialNetworkLink: datum.SocialNetworkLink ? datum.SocialNetworkLink: '',
          MarketPlaceLink: datum.MarketPlaceLink ? datum.MarketPlaceLink: '',
          StandaloneWebsiteLink: datum.StandaloneWebsiteLink ? datum.StandaloneWebsiteLink: '',
          SellerId : datum.SellerId ? datum.SellerId:'',
          SellerName : datum.SellerName ? datum.SellerName:'',
          PhoneNumber1 : datum.PhoneNumber1 ? datum.PhoneNumber1:'',
          PhoneNumber2 : datum.PhoneNumber2 ? datum.PhoneNumber2:'',
          EmailAddress1 : datum.EmailAddress1 ? datum.EmailAddress1:'',
          EmailAddress2 : datum.EmailAddress2 ? datum.EmailAddress2:'',
          Address : datum.Address ? datum.Address:''
        }
      });
       if(type == 'keyword'){
        new Angular2Csv(excelDataModified,'Brand_'+this.brandName+'_Keyword_'+this.sellerName+'_URLs',{ headers: Object.keys(excelDataModified[0])});
       }else if(type == 'product'){
        new Angular2Csv(excelDataModified,'Brand_'+this.brandName+'_Product_'+this.sellerName+'_URLs',{ headers: Object.keys(excelDataModified[0])});
       }else{
         //Export
      new Angular2Csv(excelDataModified,'Brand_'+this.brandName+'_Seller_'+this.sellerName+'_URLs',{ headers: Object.keys(excelDataModified[0])});

       }
      
      
      }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error(this.errorDscrp,this.errorMsg);
          this.authServiceObj.logout(true);
        }
      }      
    })
  }

  getScreenshots(template: TemplateRef<any>, sellerName: any, type:any, keyword:any){    
    // console.log('^^^^^^keyword--',keyword)  
    this.sellerName = sellerName;
    this.sellerDetails.seller_name = sellerName;
    if(keyword){
      this.sellerName = keyword;
    }
    this.sellerDetails.type = type;  
    // console.log('^^^^^^this.sellerDetails--',this.sellerDetails)  
    this.loaderService.display(true);
    this.dataAnalysisService.getScreenShotData(this.sellerDetails).subscribe( (res) => {
      this.loaderService.display(false);
      if(res.json().status == "success"){
        this.urlData = res.json().data_found;
        this.modalRefScreenshot = this.modalService.show(template, { class: 'modal-screenshot' }); 
      }else{
        if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
          //this.toastr.error('Invalid User','Request Failed');
          this.authServiceObj.logout(true);
        }
      } 
      

    });
    
  }

  onSort(orderby: string, id: any) {
		if (this.urlData["orderby"] == orderby) {
			if (this.urlData["order"] === "asc") {
				this.urlData["order"] = "desc";
			} else {
				this.urlData["order"] = "asc";
			}
		} else {
			this.urlData["orderby"] = orderby;
      this.urlData["order"] = "desc";
    }
    let order = this.urlData["order"];
    this.urlData = this.dataAnalysisService.sortUrlData(this.urlData, this.urlData["orderby"], this.urlData["order"]);
    this.urlData["orderby"] = orderby;
    this.urlData["order"] = order;
   }

  //screenshot thumbnail modal
  openModalThumbnail(template: TemplateRef<any>, data) {
    this.modalRefThumbnail = this.modalService.show(template, { class: 'modal-thumbnail' });
    this.thumbnail = data;
  }

  //carousal modal
  openModalScreen(template: TemplateRef<any>, id: number) {
    setTimeout(() => { this.activeIndex = (id); }, 0);
    this.modalRefScreen = this.modalService.show(template, { class: 'modal-screen' });
  } 

  updateChart() {
    if (this.selectedSeller && this.selectedCategory && this.likeData[this.selectedSeller] && this.likeData[this.selectedSeller][this.selectedCategory]) {
      this.chartData = this.likeData[this.selectedSeller][this.selectedCategory].map(d => ({
        month: d.period,
        value: d.likes
      }));
      
      this.chartData.sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime());
    } else {
      // If data is not available, set chartData to an empty array
      this.chartData = [];
      console.log('Data not available for selected seller and category');
    }
  }

  loadLikesInitialData() {
    this.fetchDataForAllSellers();
  }

  onSelectionChange() {
    if (this.selectedCategory !== this.previousCategory) {
      // Category has changed, fetch data for all sellers
      this.fetchDataForAllSellers();
    } else {
      // Only seller has changed, fetch data for selected seller
      this.fetchDataForSeller(this.selectedSeller);
    }
    this.previousCategory = this.selectedCategory;
  }

  fetchDataForAllSellers() {
    const params = {
      ...this.request,
      type: this.selectedCategory,
      isBase: 'true',
      seller_name: null
    };

    this.dataAnalysisService.getSellerLikeAnalysis(params).subscribe((response: any) => {
      if (response.status === 'success' && response.seller_list !=null) {
          this.sellers = response.seller_list.map((seller: any) => seller.seller_name) || [];
          this.sellers.forEach(seller => {
            this.likeData[seller] = {
            m_commerce: [],
            e_commerce: [],
            social_media: []
          };
        });
        
        if (response.likes) {
          const sellerName = response.likes.seller_name;
          const category = response.likes.type;
          this.likeData[sellerName][category] = response.likes.like_data;
          this.selectedSeller = sellerName;
        
        } else {
          // If no likes data, select the first seller as default
          this.selectedSeller = this.sellers[0] || null;
        }
      }
      this.updateChart();
    },
    (error) => {
      console.error('Error loading initial data:', error);
    });
  }

  fetchDataForSeller(sellerName: string) {
    const params = {
      ...this.request,
      type: this.selectedCategory,
      isBase: 'false',
      seller_name: sellerName
    };

    this.dataAnalysisService.getSellerLikeAnalysis(params).subscribe((response: any) => {
      if (response.status === 'success' && response.likes) {
        const sellerName = response.likes.seller_name;
        const category = response.likes.type;
        this.likeData[sellerName][category] = response.likes.like_data;
      }
      this.updateChart();
    });
  }

}
